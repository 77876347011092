import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/products/banner-produk.png"
import Seo from "../../components/seo"

const HpvXpressMatrix = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="HPV XpressMatrix™ Kit" />

      <Header productsPage />

      <BannerHeader
        background={bg}
        title="HPV XpressMatrix™ Kit"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="active">
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/indigen-real-time">
                        INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/diago-t790m">
                        DIAGO T790M Mutation Detection Kit
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/products/hpvNew.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="row m-b30">
                      <div class="col-lg-12">
                        <div class="dlab-tabs  product-description tabs-site-button">
                          <h4 className="dlab-title mt-2">
                            HPV XpressMatrix™ Kit
                          </h4>
                          <Tabs>
                            <TabList className="nav nav-tabs ">
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Description</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Test Principle</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Intended Use</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Additional Information</Link>
                              </Tab>
                            </TabList>

                            <TabPanel class="tab-pane">
                              <p className="text-justify">
                                Cervical cancer is the second most common cancer
                                caused death in women. The most important risk
                                factor for the development of carvical cancer is
                                genital infection with human papillomavirus
                                (HPV). To date, more than 100 HPV types were
                                identified, and approximately 40 types are
                                considered genital HPVs. These were classified
                                as Low-Risk (LR) and High-Risk (HR) types. LR
                                types can cause genital warts or benign
                                low-grade abnormalities in cervical cells, but
                                not associated with cervical cancer. However,
                                the HR types can induce cervical dysplasias and
                                invasive cervical cancer. HPV XpressMatrix™ is
                                and In Vitro diagnostic (IVD) test kit to
                                identify and genotype 21 HPV subtypes including
                                HR and LR types.
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                HPV XpressMatrix™ make use of PCR amplification
                                and Hybridization technology to genotype samples
                                with spesific HPV DNA probes. Based on the
                                principle of hybridization of double-stranded
                                DNA complementary, the hybridization technology
                                works by direct targeting amplified DNA
                                molecules toward specific DNA probes immobilized
                                on membrane hence enables hybridization to occur
                                and obtain specific genotyping result.
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                The Human Papillomavirus (HPV) XpressMatrix™
                                Test kit is based on the use of target
                                amplification and hybridization methods for the
                                detection of the following 21 HPV genotypes.
                                High-risk HPV types : HPV 16, 18, 31, 33, 35,
                                39, 45, 51, 52, 53, 56, 58, 59, 66, 88 Low-risk
                                HPV types : HPV 6, 11, 42, 43, 44, 81
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane">
                              <StaticImage src="../../images/products/infoxpressmatrix.png" />
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default HpvXpressMatrix
